import { useEffect, useState } from 'react'
import { LinkProps, useNavigate } from 'react-router-dom'

export type ChildItem = {
  id: number | string
  label: string
  link: string
  parentId?: string
  badgeColor?: string
  badgeName?: string
  isChildItem?: boolean
  click?: LinkProps['onClick']
  stateVariables?: boolean
  childItems?: ChildItem[]
}

export type NavItem = {
  id?: string
  label: string
  icon?: string
  link?: string
  stateVariables?: boolean
  isHeader?: boolean
  click?: LinkProps['onClick']
  subItems?: ChildItem[]
  badgeColor?: string
  badgeName?: string
}

const useNavData = () => {
  const history = useNavigate()
  //state data
  const [isDashboard, setIsDashboard] = useState(false)
  const [isUsers, setIsUsers] = useState(false)
  const [isCourses, setIsCourses] = useState(false)
  const [isFacilityManagement, setIsFacilityManagement] = useState(false)
  const [isReporting, setIsReporting] = useState(false)
  const [isSupport, setIsSupport] = useState(false)
  const [isCalendar, setIsCalendar] = useState(false)
  const [isCommunication, setIsCommunication] = useState(false)
  const [isSettings, setIsSettings] = useState(false)

  const [currentState, setCurrentState] = useState('Dashboard')

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target?.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')
      const iconItems = ul?.querySelectorAll('.nav-icon.active')
      iconItems?.forEach(item => {
        item.classList.remove('active')
        const id = item.getAttribute('subitems')
        if (id && document.getElementById(id)) {
          document.getElementById(id)?.classList.remove('show')
        }
      })
    }
  }

  useEffect(() => {
    if (currentState !== 'dashboard') {
      setIsDashboard(false)
    }
    if (currentState !== 'users') {
      setIsUsers(false)
    }
    if (currentState !== 'courses') {
      setIsCourses(false)
    }
    if (currentState !== 'facilityManagement') {
      setIsFacilityManagement(false)
    }
    if (currentState !== 'reporting') {
      setIsReporting(false)
    }
    if (currentState !== 'support') {
      setIsSupport(false)
    }
    if (currentState !== 'calendar') {
      setIsCalendar(false)
    }
    if (currentState !== 'communication') {
      setIsCommunication(false)
    }
    if (currentState !== 'settings') {
      setIsSettings(false)
    }
  }, [
    history,
    currentState,
    isDashboard,
    isUsers,
    isCourses,
    isFacilityManagement,
    isReporting,
    isSupport,
    isCalendar,
    isSettings,
  ])

  const navItems: NavItem[] = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'ri-apps-2-line',
      link: '/',
      stateVariables: isDashboard,
      click: function (event) {
        event.preventDefault()
        setIsDashboard(!isDashboard)
        setCurrentState('dashboard')
        updateIconSidebar(event)
      },
    },
    {
      id: 'userManagement',
      label: 'User Management',
      icon: 'ri-group-line',
      click: function (e) {
        e.preventDefault()
        setIsUsers(!isUsers)
        setCurrentState('users')
        updateIconSidebar(e)
      },
      stateVariables: isUsers,
      subItems: [
        {
          id: 'viewUserListing',
          label: 'User Listing',
          link: '/user-listing',
          parentId: 'users',
        },
        {
          id: 'viewAdminListing',
          label: 'Admin Listing',
          link: '/admin-listing',
          parentId: 'users',
        },
        {
          id: 'viewImportLog',
          label: 'Import Log',
          link: '/import-log',
          parentId: 'users',
        },
      ],
    },
    {
      id: 'courses',
      label: 'Courses',
      icon: 'ri-pages-line',
      click: function (e) {
        e.preventDefault()
        setIsCourses(!isCourses)
        setCurrentState('courses')
        updateIconSidebar(e)
      },
      stateVariables: isCourses,
      subItems: [
        {
          id: 'viewCourseListing',
          label: 'All Courses',
          link: '/courses',
          parentId: 'courses',
        },
        // {
        //   id: 'scheduledCourses',
        //   label: 'Online Course Categories',
        //   link: '/courses/online-categories',
        //   parentId: 'courses',
        // },
        // {
        //   id: 'scheduledCourses',
        //   label: 'Online Course Tags',
        //   link: '/courses/online-tags',
        //   parentId: 'courses',
        // },
        // {
        //   id: 'scheduledCourses',
        //   label: 'Scheduled Courses',
        //   link: '/courses/scheduled-courses',
        //   parentId: 'courses',
        // },
        // {
        //   id: 'scheduledCourses',
        //   label: 'Scheduled Course Categories',
        //   link: '/courses/scheduled-categories',
        //   parentId: 'courses',
        // },
        /*{
          id: 'coursePackages',
          label: 'Course Packages',
          link: '/courses/course-packages',
          parentId: 'courses',
        },*/
        // {
        //   id: 'reportsAndAnalytics',
        //   label: 'Reports and Analytics',
        //   link: '/courses/reports-and-analytics',
        //   parentId: 'courses',
        // },
        {
          id: 'viewMediaLibrary',
          label: 'Media Library',
          link: '/courses/media-library',
          parentId: 'courses',
        },
      ],
    },
    {
      id: 'facilityManagement.viewFacilityManagement',
      label: 'Facility Management',
      icon: 'ri-briefcase-line',
      link: '/facility-management',
      click: function (e) {
        e.preventDefault()
        setIsFacilityManagement(!isFacilityManagement)
        setCurrentState('facilityManagement')
        updateIconSidebar(e)
      },
      stateVariables: isFacilityManagement,
    },
    {
      id: 'reporting',
      label: 'Reporting',
      icon: 'ri-file-list-3-line',
      link: '/reporting',
      click: function (e) {
        e.preventDefault()
        setIsReporting(!isReporting)
        setCurrentState('reporting')
        updateIconSidebar(e)
      },
      stateVariables: isReporting,
      subItems: [
        {
          id: 'viewCourseHistoryReport',
          label: 'Course History',
          link: '/courses/history',
          parentId: 'reporting',
        },
        {
          id: 'viewCourseHistorySummaryReport',
          label: 'Completed Courses',
          link: '/courses/history/summary',
          parentId: 'reporting',
        },
        {
          id: 'viewUserReport',
          label: 'User Report',
          link: '/user/report',
          parentId: 'reporting',
        },
        // {
        //   id: 'viewCourseReport',
        //   label: 'Course Report',
        //   link: '/courses/report',
        //   parentId: 'reporting',
        // },
      ],
    },
    {
      id: 'support',
      label: 'Support',
      icon: 'ri-service-line',
      click: function (e) {
        e.preventDefault()
        setIsSupport(!isSupport)
        setCurrentState('support')
        updateIconSidebar(e)
      },
      stateVariables: isSupport,
      subItems: [
        {
          id: 'viewTutorialsListing',
          label: 'Tutorials',
          link: '/support/tutorials',
          parentId: 'support',
        },
        {
          id: 'viewFAQListing',
          label: 'Knowledge base',
          link: 'https://app.intercom.com/a/apps/dbxgtj0g/articles/articles/list/all',
          parentId: 'support',
        },
        {
          id: 'viewSupportCategories',
          label: 'Support Categories',
          link: '/support/categories',
          parentId: 'support',
        },
      ],
    },
    {
      id: 'calendar.viewCalendar',
      label: 'Calendar',
      icon: 'ri-calendar-2-line',
      link: '/calendar',
      click: function (e) {
        e.preventDefault()
        setIsCalendar(!isCalendar)
        setCurrentState('calendar')
      },
      stateVariables: isCalendar,
    },
    {
      id: 'communication',
      label: 'Communication',
      icon: 'ri-mail-open-line',
      click: function (e) {
        e.preventDefault()
        setIsCommunication(!isCommunication)
        setCurrentState('communication')
        updateIconSidebar(e)
      },
      stateVariables: isCommunication,
      subItems: [
        {
          id: 'viewSurveyListing',
          label: 'Surveys',
          link: '/surveys',
          parentId: 'communication',
        },
        {
          id: 'viewNotificationListing',
          label: 'Push Notifications',
          link: '/push-notifications',
          parentId: 'communication',
        },
        {
          id: 'viewCompanyNewsListing',
          label: 'Company News',
          link: '/company-news',
          parentId: 'communication',
        },
      ],
    },
    {
      id: 'settings',
      label: 'Settings',
      icon: 'ri-settings-3-line',
      click: function (e) {
        e.preventDefault()
        setIsSettings(!isSettings)
        setCurrentState('settings')
        updateIconSidebar(e)
      },
      stateVariables: isSettings,
      subItems: [
        {
          id: 'viewDefaultCourseSettings',
          label: 'Default Settings',
          link: '/course-settings',
          parentId: 'settings',
        },
        {
          id: 'viewInstructorListing',
          label: 'Instructor Management',
          link: '/instructor-management',
          parentId: 'settings',
        },
        {
          id: 'viewRolesAndPermissions',
          label: 'User Roles & Permissions',
          link: '/role-permissions',
          parentId: 'settings',
        },
        // {
        //   id: 'categories',
        //   label: 'Categories',
        //   link: '/forms-pickers',
        //   parentId: 'settings',
        //   isChildItem: true,
        // },
        /*{
          id: 'certificates',
          label: 'Certificates',
          link: '/forms-range-sliders',
          parentId: 'settings',
        },*/
        // {
        //   id: 'userSetup',
        //   label: 'User Setup Questions',
        //   link: '/forms-validation',
        //   parentId: 'settings',
        // },
      ],
    },
  ]
  return navItems
}
export default useNavData
