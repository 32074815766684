import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  GetLogsDTO,
  LOG_ERROR_STATUS,
  LOG_TYPES,
  LogsSortBy,
  OrderType,
  UserManagementPermissions,
  UsersSortBy,
} from '../../../sharedTypes'
import { handleError } from '../../../helpers/toast_helper'
import { getImportLogs } from '../../../helpers/api_helper'
import { Table, UncontrolledTooltip } from 'reactstrap'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import { Link } from 'react-router-dom'
import { usePermissions } from '../../../hooks/usePermissions'
import _ from 'lodash'
import { TabProps, Tabs } from '../types'
import UserInfo from '../../UserManagment/ImportLogs/UserInfo'
import moment from 'moment/moment'
import { useAppSelector } from '../../../hooks/redux'
import UserDepartments from '../../../Components/Common/UserDepartments'

const Columns = [
  {
    name: 'ID',
    sortBy: LogsSortBy.EMPLOYEE_ID,
    style: { width: 100 },
  },
  {
    name: 'User',
    style: { width: 200 },
  },
  {
    name: 'Department',
    sortBy: LogsSortBy.DEPARTMENT_NAME,
    style: { width: 120 },
  },
  {
    name: 'Notes',
    sortBy: LogsSortBy.NOTES,
    style: { width: 200 },
  },
]

const ImportErrors = ({ setTotalCounts }: TabProps) => {
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )
  const [fetchData, setFetchData] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const scrollPositionRef = useRef(0)

  const hasPermission = usePermissions(
    UserManagementPermissions.VIEW_USER_PROFILE,
  )

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<GetLogsDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    logs: [],
  })

  const [query, setQuery] = useState<GetLogsDTO.Request>({
    page: 1,
    limit: 30,
    facilityIds: [],
    departmentIds: [],
    positionIds: [],
    sortBy: LogsSortBy.IMPORT_DATE,
    orderBy: OrderType.DESC,
    type: LOG_TYPES.ERROR,
    searchDate: [moment().toDate(), moment().toDate()],
    includeAgencyEmployees: false,
    status: LOG_ERROR_STATUS.OPEN,
  })

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      const _query = _.omitBy(query, _.isNil)
      if (!_.isEmpty(_query.searchDate)) {
        _query.searchDate = [
          moment(_query.searchDate[0]).format('YYYY-MM-DD'),
          moment(_query.searchDate[1]).format('YYYY-MM-DD'),
        ]
      }
      getImportLogs(_query)
        .then(res => {
          setData({
            logs:
              query.page === 1
                ? res.data.logs
                : [...data.logs, ...res.data.logs],
            page: res.data.page,
            pages: res.data.pages,
            count: res.data.count,
          })
          if (query.page === 1) {
            setTotalCounts(prev => ({
              ...prev,
              [Tabs.IMPORT_ERRORS]: res.data.count,
            }))
          }
          afterScroll()
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  useEffect(() => {
    if (selectedGroupId && !_.isEqual([selectedGroupId], query.groupIds)) {
      setQuery(q => ({ ...q, groupIds: [selectedGroupId], facilityIds: [] }))
    }

    if (
      selectedFacilityId &&
      !_.isEqual([selectedFacilityId], query.facilityIds)
    ) {
      setQuery(q => ({ ...q, facilityIds: [selectedFacilityId], groupIds: [] }))
    }

    if (!selectedFacilityId && !selectedGroupId) {
      setQuery(q => ({ ...q, facilityIds: [], groupIds: [] }))
    }

    setFetchData(true)
  }, [selectedFacilityId, selectedGroupId])

  const handleSort = useCallback((column: LogsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
      page: 1,
    }))
  }, [])

  const afterScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPositionRef.current
    }
  }

  const handleScroll = () => {
    if (containerRef.current) {
      scrollPositionRef.current = containerRef.current.scrollTop

      const { scrollTop, clientHeight, scrollHeight } = containerRef.current
      if (
        scrollHeight - (scrollTop + clientHeight) <= 1 &&
        query.page !== data.pages
      ) {
        setQuery(prev => ({ ...prev, page: (query.page as number) + 1 }))
      }
    }
  }

  return (
    <NoResultTableWrapper
      isLoading={isLoading}
      isFiltering={false}
      pages={data.pages}
    >
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className='scroll-not-visible'
        id='dashboard-import-logs'
      >
        <div className='table-card'>
          <div className='overflow-x-auto'>
            <Table
              className='align-middle mb-0'
              style={{ tableLayout: 'fixed' }}
            >
              <thead>
                <tr className='text-muted fs-14'>
                  {Columns.map(column => (
                    <th
                      scope='col'
                      className='align-middle'
                      style={column.style}
                      key={column.name}
                    >
                      <div className='d-flex align-items-center gap-1'>
                        <span>{column.name}</span>
                        {!!column.sortBy && (
                          <ColumnSortIcon<LogsSortBy>
                            sortOrder={query.orderBy}
                            sortedColumn={query.sortBy}
                            column={column.sortBy}
                            handleSort={handleSort}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.logs.map(log => (
                  <tr key={log.id} className='fs-14'>
                    <td>
                      {log.userId ? (
                        <Link
                          to={
                            hasPermission
                              ? `/user-listing/profile/${log.userId}`
                              : '#'
                          }
                          target='_blank'
                          className='fw-normal text-primary'
                        >
                          <span>{log.employeeId}</span>
                        </Link>
                      ) : (
                        <span>{log.employeeId}</span>
                      )}
                    </td>
                    <td>
                      <UserInfo
                        log={log}
                        globalSearch={''}
                        displayDetails={true}
                      />
                    </td>

                    {log.user?.departments ? (
                      <UserDepartments
                        departments={log.user?.departments}
                        key={query.key || ''}
                      />
                    ) : (
                      <td>
                        <span>{log.rawUser?.department || ''}</span>
                      </td>
                    )}
                    <td>
                      {log.notes.length && (
                        <UncontrolledTooltip
                          placement='top'
                          target={`showMore${log.id}`}
                        >
                          {log.notes}
                        </UncontrolledTooltip>
                      )}
                      <span
                        id={`showMore${log.id}`}
                        className='import-error-note'
                      >
                        {log.notes || ''}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </NoResultTableWrapper>
  )
}

export default ImportErrors
