import React, { useState } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { USER_COURSE_STATUSES } from '../../../sharedTypes'
import DetailsInfoModal from '../../../Components/Modals/DetailsInfoModal'

interface FilterTabsProps {
  navTab?: USER_COURSE_STATUSES
  navToggle: (tab?: USER_COURSE_STATUSES) => void
  style?: React.CSSProperties
}
const tabs = [
  { label: 'All', value: undefined },
  {
    label: 'No Courses',
    value: USER_COURSE_STATUSES.NO_COURSES,
    info: 'User is registered but has no courses assigned.',
  },
  {
    label: 'On Schedule',
    value: USER_COURSE_STATUSES.ON_SCHEDULE,
    info: 'User has no overdue courses.',
  },
  {
    label: 'Pending Completion',
    value: USER_COURSE_STATUSES.PENDING_COMPLETION,
    info: 'User has overdue courses.',
  },
  {
    label: 'Complete',
    value: USER_COURSE_STATUSES.COMPLETE,
    info: 'User has completed all of the courses assigned.',
  },
]

const FilterTabs = ({ navTab, navToggle, style }: FilterTabsProps) => {
  const [openModalId, setOpenModalId] = useState<number | null>(null)
  return (
    <Nav className='nav-customs-bordered' style={style}>
      {tabs.map((tab, id) => (
        <NavItem key={tab.label}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
            }}
          >
            {tab.label}
            <DetailsInfoModal
              info={tab.info}
              id={id}
              name={'user-break-down'}
              openModalId={openModalId}
              setOpenModalId={setOpenModalId}
              onHover={true}
            />
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
