import React, { Fragment } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

interface DetailsInfoModalProps {
  id: number
  name: string
  info?: string
  openModalId: number | null
  setOpenModalId: (id: number | null) => void
  onHover?: boolean
}

const DetailsInfoModal = ({
  id,
  name,
  info,
  openModalId,
  setOpenModalId,
  onHover,
}: DetailsInfoModalProps) => {
  const elementId = `${name}-${id}`
  const handleModalToggle = () => {
    if (openModalId === id) {
      setOpenModalId(null)
    } else {
      setOpenModalId(id)
    }
  }

  return (
    <Fragment>
      {info && (
        <i
          style={{
            marginLeft: 4,
            color: openModalId === id ? '#0039C7' : 'text-muted',
          }}
          className='ri-information-line cursor-pointer fs-16 text-muted act'
          id={elementId}
          onClick={handleModalToggle}
          onMouseEnter={() => onHover && setOpenModalId(id)}
          onMouseLeave={() => onHover && setOpenModalId(null)}
        />
      )}

      {info && (
        <UncontrolledTooltip
          className='cursor-pointer'
          placement='top'
          target={elementId}
          trigger='hover focus'
          onClick={handleModalToggle}
          isOpen={openModalId === id}
        >
          {info}
        </UncontrolledTooltip>
      )}
    </Fragment>
  )
}

export default DetailsInfoModal
