import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Spinner,
  Label,
} from 'reactstrap'
import { useFormik } from 'formik'
import {
  SurveyStatusTypes,
  TSurveyTranslationItem,
  QuestionTypes,
  IQuestionForm,
  TLanguage,
  CommunicationPermissions,
  SurveyRequest,
} from '../../../sharedTypes'
import Select, { MultiValue } from 'react-select'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import { AsyncSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import _ from 'lodash'
import { toast } from 'react-toastify'
import {
  errorToastOptions,
  successToastOptions,
  warningToastOptions,
} from '../../../helpers/toast_helper'
import {
  getSurveyById,
  putSurvey,
  postSurvey,
} from '../../../helpers/api/surveys'
import SurveyQuestions from './SurveyQuestions'
import { surveySchema } from '../../../schemas'
import SurveyTranslations from './SurveyTranslations'
import PreviewSurveyModal from '../../../Components/Modals/PreviewSurveyModal'
import {
  AssignTo,
  AssignToOptions,
  IAssignToOption,
  Option,
} from '../../../sharedTypes'
import { useAppSelector } from '../../../hooks/redux'
import {
  fetchRecipientsOptions,
  mapRecipientsToOptions,
} from '../../../utils/users'
import { usePermissions } from '../../../hooks/usePermissions'
import { ENGLISH_LANGUAGE_CODE } from '../../../helpers/common'

export interface IForm {
  id?: number
  status: SurveyStatusTypes
  sendDate?: Date | undefined
  deadline?: Date | undefined
  sendTo: AssignTo
  recipients: number[] | null
  allowAnonymousResponses: boolean
  translations: TSurveyTranslationItem[]
  questions: IQuestionForm[]
}

const ManageSurvey = () => {
  document.title = 'Manage Survey | Mastered - Admin & Dashboard'

  const navigate = useNavigate()
  const languages = useAppSelector(state => state.Languages.list)
  const { id } = useParams()
  const isEditState = !!id
  const datePickerRef = useRef<Flatpickr>(null)
  const sendDatePickerRef = useRef<Flatpickr>(null)
  const [searchValue, setSearchValue] = useState('')
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [triggerValidation, setTriggerValidation] = useState<null | string>(
    null,
  )
  const DEFAULT_LANGUAGE = {
    code: 'en',
    name: 'English',
    id: 1,
  }

  const initialAnswer = { answer: '', order: 1, id: Date.now() }

  const [selectedLanguageId, setSelectedLanguageId] = useState<number>(
    DEFAULT_LANGUAGE.id,
  )
  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false)
  const hasPermissionToSend = usePermissions(
    CommunicationPermissions.SEND_SCHEDULE_SURVEY,
  )

  useEffect(() => {
    if (!isEditState && languages.length) {
      const lang = languages.find(l => l.id === selectedLanguageId) as TLanguage
      handleLanguageChange([{ value: lang.id, label: lang?.name }])
    }
  }, [languages, isEditState])

  useEffect(() => {
    if (id) {
      getSurveyById(+id).then(({ survey, recipients }) => {
        const translations = survey.translations.map(t => ({
          ...t.content,
          languageId: t.languageId,
        }))

        const questions = survey.questions.map((q: any) => {
          return {
            type: q.type,
            questions: [q],
          }
        })

        form.setFieldValue('allowComments', survey.allowComments)
        form.setFieldValue(
          'allowAnonymousResponses',
          survey.allowAnonymousResponses,
        )

        form.setFieldValue('translations', translations)
        form.setFieldValue('questions', questions)
        form.setFieldValue(
          'deadline',
          survey.deadline ? moment(survey.deadline).toDate() : undefined,
        )
        form.setFieldValue(
          'sendDate',
          survey.sendDate ? moment(survey.sendDate).toDate() : undefined,
        )

        form.setFieldValue('status', SurveyStatusTypes.DRAFT)
        form.setFieldValue('sendTo', survey.sendTo)
        form.setFieldValue('recipients', mapRecipientsToOptions(recipients))
      })
    }
  }, [id])

  const onSubmit = useCallback(
    async ({ recipients, deadline, status, questions, ...rest }: IForm) => {
      try {
        const payload: SurveyRequest = {
          ...rest,
          status,
          questions: _.flatten(_.map(questions, 'questions')),
          recipients: _.map(recipients, 'value'),
          deadline: deadline ? moment(deadline).format('YYYY-MM-DD') : null,
        }

        isEditState ? await putSurvey(+id, payload) : await postSurvey(payload)

        const message =
          isEditState && status !== SurveyStatusTypes.SENT
            ? 'Successfully edited'
            : `Successfully ${
                status === SurveyStatusTypes.SENT ? 'sent' : 'saved'
              }`
        toast(message, successToastOptions)
        navigate('/surveys')
      } catch (e) {}
    },
    [isEditState],
  )

  const form = useFormik<IForm>({
    enableReinitialize: false,
    initialValues: {
      sendTo: AssignTo.USERS,
      recipients: [],
      deadline: undefined,
      sendDate: undefined,
      status: SurveyStatusTypes.DRAFT,
      allowAnonymousResponses: false,
      translations: [],
      questions: [],
    },
    onSubmit,
    validationSchema: surveySchema,
  })

  const languageOptions: Option<number>[] = useMemo(
    () => languages.map(l => ({ label: l.name, value: l.id })),
    [languages],
  )
  const selectedLanguages = useMemo(
    () =>
      languageOptions.filter(l =>
        _.map(form.values.translations, 'languageId').includes(l.value),
      ),
    [form.values.translations, languageOptions],
  )

  const defaultQuestion = (ids: number[]) => ({
    type: QuestionTypes.MULTIPLE_CHOICE,
    questions: ids.map(languageId => ({
      languageId,
      question: '',
      type: QuestionTypes.MULTIPLE_CHOICE,
      answers: [{ ...initialAnswer, id: Date.now() } as any],
    })),
  })

  const addNewQuestion = useCallback(() => {
    const ids = _.map(selectedLanguages, 'value')
    form.setFieldValue('questions', [
      ...form.values.questions,
      defaultQuestion(ids),
    ])
  }, [form.values, selectedLanguages])

  const updateTranslationsAccordingToLanguageChange = useCallback(
    (languageIds: number[]) => {
      const translations = form.values.translations.filter(t =>
        languageIds.includes(t.languageId),
      )

      languageIds.forEach(languageId => {
        const translationExists = _.find(form.values.translations, {
          languageId,
        })

        if (!translationExists) {
          translations.push({
            name: '',
            description: '',
            title: '',
            message: '',
            languageId,
          })
        }
      })

      form.setFieldValue('translations', translations)
    },
    [form.values.translations],
  )

  const updateQuestionsAccordingToLanguageChange = useCallback(
    (languageIds: number[]) => {
      if (form.values.questions.length) {
        const questions = form.values.questions.map(q => {
          const questions = q.questions.filter(t =>
            languageIds.includes(t.languageId),
          )

          const filteredLangIds = _.map(questions, 'languageId')

          const newLangIds = languageIds.filter(
            id => !filteredLangIds.includes(id),
          )

          newLangIds.forEach(languageId => {
            questions.push({
              languageId,
              question: '',
              answers: [{ ...initialAnswer, id: Date.now() } as any],
            })
          })
          return { ...q, questions }
        })
        form.setFieldValue('questions', questions)
      } else {
        form.setFieldValue('questions', [defaultQuestion(languageIds)])
      }
    },
    [form.values.questions],
  )

  const handleLanguageChange = useCallback(
    (options: MultiValue<Option<number>>) => {
      const languageIds = _.map(options, 'value')
      form.handleBlur('languages')

      const isRemove = _.size(options) < _.size(form.values.translations)

      updateTranslationsAccordingToLanguageChange(languageIds)
      updateQuestionsAccordingToLanguageChange(languageIds)

      if (isRemove) {
        setDefaultLanguageId()
      }
    },
    [form.values],
  )

  const setDefaultLanguageId = useCallback(() => {
    setSelectedLanguageId(DEFAULT_LANGUAGE.id)
  }, [])

  const fetchEntities = (inputValue?: string) => {
    const params = {
      page: 1,
      limit: 10,
      key: inputValue,
    }

    return fetchRecipientsOptions(
      form.values.sendTo,
      params,
      isEditState
        ? CommunicationPermissions.EDIT_SURVEY
        : CommunicationPermissions.CREATE_CLONE_SURVEY,
    ).then(r => r.data)
  }

  const onDeleteQuestion = useCallback(
    (index: number) => {
      const questions = [...form.values.questions]
      if (questions.length === 1) {
        toast('At least one question is required', warningToastOptions)
      } else {
        questions.splice(index, 1)
        form.setFieldValue('questions', questions)
      }
    },
    [form.values],
  )

  const onSubmitWithStatus = useCallback(
    async (status: SurveyStatusTypes) => {
      await form.setFieldValue('status', status)
      form
        .validateForm()
        .then(errors => {
          if (Object.keys(errors).length) {
            toast(
              'Some required fields need to be filled out',
              errorToastOptions,
            )
            return
          }
        })
        .finally(() => {
          form.submitForm()
        })
    },
    [form],
  )

  const onReOrder = useCallback(
    (result: any) => {
      if (!result.destination) {
        return
      }

      const questions = form.values.questions

      const [reorderedItem] = questions.splice(result.source.index, 1)

      questions.splice(result.destination.index, 0, reorderedItem)

      questions.forEach((question: any, index: number) => {
        question.order = index + 1
      })

      form.setFieldValue(`questions`, questions)
      setForceUpdate(!forceUpdate)
    },
    [form.values, forceUpdate],
  )

  return (
    <div className='page-content'>
      <Container fluid className='survey-container'>
        <BreadCrumb
          title={isEditState ? 'Edit' : 'Add'}
          items={[
            {
              title: 'Surveys',
              linkTo: '/surveys',
            },
            {
              title: `${isEditState ? 'Edit' : 'Add new'}`,
              active: true,
            },
          ]}
        />
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col md={6}>
              <Card className='surveys-manage-page'>
                <CardHeader className='border-bottom-0 text-muted pb-0 fs-16'>
                  General Information
                </CardHeader>
                <CardBody>
                  <div className='vstack gap-4 mt-3'>
                    <Row>
                      <Col md={8}>
                        <div>
                          <label htmlFor='sendTo' className='form-label'>
                            Send To
                          </label>
                          <Select<IAssignToOption, false>
                            name='sendTo'
                            id='sendTo'
                            isSearchable={false}
                            onChange={option => {
                              form.setFieldValue(
                                'sendTo',
                                option?.value || undefined,
                              )
                              form.setFieldValue('recipients', [])
                            }}
                            onBlur={form.handleBlur}
                            value={AssignToOptions.find(
                              o => o.value === form.values.sendTo,
                            )}
                            options={AssignToOptions}
                            className='select2-container'
                            classNamePrefix='select2-selection form-select'
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div>
                          <label htmlFor='sendTo' className='form-label'>
                            Deadline*
                          </label>
                          <div className='form-icon right'>
                            <Flatpickr
                              className={`form-control form-control-icon ${
                                form.touched.deadline && form.errors.deadline
                                  ? ' is-invalid'
                                  : ''
                              }`}
                              ref={datePickerRef}
                              id='date'
                              name='deadline'
                              placeholder='mm-dd-yyyy'
                              onChange={option => {
                                form.setFieldValue('deadline', option[0])
                              }}
                              onBlur={() => {
                                form.setFieldTouched('deadline', true)
                              }}
                              value={form.values.deadline}
                              options={{
                                dateFormat: 'd-m-Y',
                                formatDate: date =>
                                  moment(date).format('MM/DD/YYYY'),
                                minDate: moment(form.values.sendDate)
                                  .add(1, 'days')
                                  .toDate(),
                                allowInvalidPreload: true,
                              }}
                            />
                            {form.values.deadline && (
                              <i
                                className='ri-close-line fs-16 text-danger'
                                onClick={() => {
                                  datePickerRef.current?.flatpickr.clear()
                                }}
                              ></i>
                            )}
                            <i className='ri-calendar-event-line fs-20'></i>
                          </div>
                          {form.errors.deadline ? (
                            <div className='invalid-feedback d-block'>
                              {form.errors.deadline}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <label htmlFor='recipients' className='form-label'>
                        {
                          AssignToOptions.find(
                            item => item.value === form.values.sendTo,
                          )?.label
                        }
                        *
                      </label>
                      <AsyncSelectWithSearch
                        key={`${form.values.sendTo}`}
                        name='recipients'
                        id='recipients'
                        onChange={option => {
                          form.setFieldValue('recipients', option)
                        }}
                        onBlur={form.handleBlur}
                        value={form.values.recipients}
                        isMulti={true}
                        isClearable={false}
                        isSearchable={true}
                        placeholder={''}
                        defaultOptions
                        loadOptions={fetchEntities}
                        onMenuScrollToBottom={() => {}}
                        inputValue={searchValue}
                        onInputChange={newInputValue => {
                          setSearchValue(newInputValue)
                        }}
                      />

                      {form.touched.recipients && form.errors.recipients ? (
                        <div className='invalid-feedback d-block'>
                          {form.errors.recipients}
                        </div>
                      ) : null}
                    </div>

                    <div className='form-check'>
                      <Input
                        className='form-check-input'
                        type='checkbox'
                        name='allowAnonymousResponses'
                        id='allowAnonymousResponses'
                        checked={form.values.allowAnonymousResponses}
                        onChange={form.handleChange}
                      />
                      <Label
                        className='form-check-label'
                        htmlFor='allowAnonymousResponses'
                      >
                        Allow Anonymous Responses
                      </Label>
                    </div>

                    <Col sm='12'>
                      <SurveyTranslations
                        form={form}
                        selectedLanguages={selectedLanguages}
                        languages={languages.filter(
                          lang => lang.code === ENGLISH_LANGUAGE_CODE,
                        )}
                        selectedLanguage={selectedLanguageId}
                        setSelectedLanguage={setSelectedLanguageId}
                      />
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6}>
              <SurveyQuestions
                forceUpdate={forceUpdate}
                onReOrder={onReOrder}
                triggerValidation={triggerValidation}
                questions={form.values.questions}
                onAdd={() => addNewQuestion()}
                onDelete={onDeleteQuestion}
                onChange={(i: number, v: IQuestionForm) => {
                  form.setFieldValue(`questions[${i}]`, v)
                }}
                languages={languages.filter(l =>
                  _.map(form.values.translations, 'languageId').includes(l.id),
                )}
              />
              <Card className='mt-4'>
                <CardBody>
                  <div>
                    <label htmlFor='date' className='form-label'>
                      Scheduled Date and Time
                    </label>
                    <Row>
                      <Col md={6}>
                        <div className='form-icon right'>
                          <Flatpickr
                            data-enable-time
                            className={`form-control form-control-icon ${
                              form.errors.sendDate &&
                              form.values.status === SurveyStatusTypes.SCHEDULED
                                ? ' is-invalid'
                                : ''
                            }`}
                            ref={sendDatePickerRef}
                            id='date'
                            name='sendDate'
                            placeholder='mm-dd-yyyy, --:--'
                            onChange={option => {
                              form.setFieldValue('sendDate', option[0])
                            }}
                            onBlur={() => {
                              form.setFieldTouched('sendDate', true)
                            }}
                            value={form.values.sendDate}
                            options={{
                              dateFormat: 'MM-DD-YYYY HH:mm',
                              formatDate: date =>
                                moment(date).format('MM/DD/YYYY HH:mm'),
                              minDate: Date.now(),
                              allowInvalidPreload: true,
                            }}
                          />
                          {form.values.sendDate && (
                            <i
                              className='ri-close-line fs-16 text-danger'
                              onClick={() => {
                                sendDatePickerRef.current?.flatpickr.clear()
                              }}
                            ></i>
                          )}
                          <i className='ri-calendar-event-line fs-20'></i>
                        </div>
                      </Col>
                      <Col
                        md={6}
                        className={`${form.values.sendDate ? 'mb-2' : ''}`}
                      >
                        <span className='fs-14 text-muted'>
                          {form.values.sendDate
                            ? 'To Send Now, leave the Scheduled Date and Time blank'
                            : 'If you do not enter a scheduled date/time, the survey will be sent now'}
                        </span>
                      </Col>
                    </Row>
                    {form.errors.sendDate &&
                    form.values.status === SurveyStatusTypes.SCHEDULED ? (
                      <div className='invalid-feedback d-block'>
                        {form.errors.sendDate}
                      </div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className='d-flex justify-content-between navigation-container sticky-row'>
            <Button
              color='light'
              className='btn-label'
              onClick={() => navigate('/surveys')}
            >
              <i className='ri-arrow-left-line label-icon align-middle me-2'></i>
              Cancel
            </Button>
            <div className='hstack gap-3'>
              {_.size(form.values.questions) ? (
                <Button
                  color='ghost-primary'
                  className='text-light-purple'
                  disabled={form.isSubmitting}
                  onClick={() => {
                    setIsOpenPreview(true)
                  }}
                >
                  See Preview
                </Button>
              ) : null}

              <Button
                color='ghost-primary'
                className='text-light-purple'
                disabled={form.isSubmitting}
                onClick={() => {
                  setTriggerValidation(_.uniqueId())
                  onSubmitWithStatus(SurveyStatusTypes.DRAFT)
                }}
              >
                Save as Draft
              </Button>
              {hasPermissionToSend && (
                <>
                  <Button
                    color='success'
                    className='btn-label right'
                    disabled={form.isSubmitting}
                    onClick={() => {
                      setTriggerValidation(_.uniqueId())
                      onSubmitWithStatus(SurveyStatusTypes.SCHEDULED)
                    }}
                  >
                    <i className='ri-calendar-2-line label-icon align-middle ms-2'></i>
                    Schedule
                  </Button>

                  <Button
                    color='primary'
                    className='btn-label right'
                    disabled={form.isSubmitting}
                    onClick={() => {
                      setTriggerValidation(_.uniqueId())
                      form.setFieldValue('sendDate', null)
                      onSubmitWithStatus(SurveyStatusTypes.SENT)
                    }}
                  >
                    <i className='ri-arrow-right-line label-icon align-middle ms-2'></i>
                    Send Now
                  </Button>
                </>
              )}
            </div>
          </div>
          <PreviewSurveyModal
            onClose={() => setIsOpenPreview(false)}
            isOpen={isOpenPreview}
            languages={languages.filter(l =>
              _.map(form.values.translations, 'languageId').includes(l.id),
            )}
            questions={_.flatten(_.map(form.values.questions, 'questions'))}
          />
        </Form>
      </Container>
    </div>
  )
}

export default ManageSurvey
