import _ from 'lodash'

import { IGroupOptions } from '../Components/Common/SelectWithSearch'
import { TCompany, TFacility, TGroup, TUser } from '../sharedTypes'

export interface Option extends IGroupOptions {
  id?: number
  disabled?: boolean
  parentType?: 'company' | 'group'
  companyId: number
  originalName?: string
}

export const getGroupOption = (
  groups: TGroup[],
  parentType: Option['parentType'],
  parentValue: Option['parentValue'],
  level: number,
  withFacility = false,
  mode = true,
) => {
  const options: Option[] = []
  groups.forEach(group => {
    const option: Option = {
      label: group.name,
      value: 'group:' + group.id,
      parentValue: parentValue,
      parentType: parentType,
      level,
      hasChildren: false,
      companyId: group.companyId,
    }
    let facilities: Option[] = []
    let subGroups: Option[] = []

    if (withFacility && !_.isEmpty(group.facilities)) {
      facilities = group.facilities.map(facility => ({
        label: mode ? facility.name + ' - Facility' : facility.name,
        value: 'facility:' + facility.id,
        parentValue: option.value,
        parentType: 'group',
        level: level + 1,
        hasChildren: false,
        companyId: group.companyId,
      }))
    }

    if (!_.isEmpty(group.subGroups)) {
      subGroups = getGroupOption(
        group.subGroups,
        'group',
        option.value,
        level + 1,
        withFacility,
        mode,
      )
    }
    option.hasChildren = !_.isEmpty(facilities) || !_.isEmpty(subGroups)
    options.push(option)
    if (!_.isEmpty(facilities)) {
      options.push(...facilities)
    }
    if (!_.isEmpty(subGroups)) {
      options.push(...subGroups)
    }
  })
  return options
}

export const getParentOption = (options: Option[], facility?: TFacility) => {
  if (facility) {
    const value = facility.groupId
      ? 'group:' + facility.groupId
      : 'company:' + facility.companyId
    const selected = options.find(option => {
      return option.value === value
    })
    if (selected) {
      return selected
    }
  }
  return options[0]
}

export const getAdminOption = (options: Option[], admin?: TUser) => {
  if (admin) {
    const value = admin.facilityId
      ? 'facility:' + admin.facilityId
      : admin.groupId
      ? 'group:' + admin.groupId
      : 'company:' + admin.companyId
    const selected = options.find(option => {
      return option.value === value
    })
    if (selected) {
      return selected
    }
  }
  return undefined
}

export const getCompanyGroupOptions = (companies: TCompany[]) => {
  const options: Option[] = []
  companies.forEach(company => {
    const option: Option = {
      label: company.name,
      value: 'company:' + company.id,
      level: 0,
      hasChildren: false,
      companyId: company.id,
    }
    if (!_.isEmpty(company.groups)) {
      const groups = getGroupOption(
        company.groups,
        'company',
        option.value,
        1,
        false,
      )

      option.hasChildren = !_.isEmpty(groups)
      options.push(option)
      options.push(...groups)
    } else {
      options.push(option)
    }
  })

  return options
}

export const getCompanyGroupWithFacilitiesOptions = (
  companies: TCompany[],
  mode = true,
) => {
  const options: Option[] = []
  companies.forEach(company => {
    const option: Option = {
      label: mode ? company.name : 'All',
      originalName: company.name,
      value: 'company:' + company.id,
      level: 0,
      hasChildren: false,
      companyId: company.id,
    }

    let facilities: Option[] = []
    let groups: Option[] = []

    if (!_.isEmpty(company.facilities)) {
      facilities = company.facilities.map(facility => ({
        label: mode ? facility.name + ' - Facility' : facility.name,
        value: 'facility:' + facility.id,
        parentValue: option.value,
        parentType: 'company',
        level: 1,
        hasChildren: false,
        companyId: company.id,
      }))
    }
    if (!_.isEmpty(company.groups)) {
      groups = getGroupOption(
        company.groups,
        'company',
        option.value,
        1,
        true,
        mode,
      )
    }

    option.hasChildren = !_.isEmpty(facilities) || !_.isEmpty(groups)
    options.push(option)
    if (!_.isEmpty(facilities)) {
      options.push(...facilities)
    }
    if (!_.isEmpty(groups)) {
      options.push(...groups)
    }
  })

  return options
}
