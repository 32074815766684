import React from 'react'

import FilterTabs, { FilterTabsProps } from '../Common/FilterTabs'
import { CoursesTabs } from '../../sharedTypes'

const tabs = [
  {
    label: 'Courses',
    value: CoursesTabs.COURSES,
    info: 'All individual courses available to be assigned to users.',
  },
  {
    label: 'Packages',
    value: CoursesTabs.PACKAGES,
    info: 'A number of courses that can be assigned to users together.',
  },
  {
    label: 'Training Days',
    value: CoursesTabs.TRAINING_DAYS,
    info: 'Live sessions taking place in one day that can be assigned to users together.',
  },
]

interface CoursesTabsProps
  extends Pick<FilterTabsProps<CoursesTabs>, 'extraTabData'> {
  activeTab: CoursesTabs
  onTabChange: (tab: CoursesTabs) => void
}

const CourseTabs = ({
  activeTab,
  onTabChange,
  extraTabData,
}: CoursesTabsProps) => (
  <FilterTabs<CoursesTabs>
    tabs={tabs}
    navTab={activeTab}
    navToggle={_activeTab => {
      if (_activeTab && _activeTab !== activeTab) {
        onTabChange(_activeTab)
      }
    }}
    extraTabData={extraTabData}
  />
)

export default CourseTabs
