import { GroupMultiSelectWithSearch } from './SelectWithSearch'
import { Option } from '../../helpers/facility'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FILTERS } from '../../pages/Reporting/CourseHistory/types'

interface AgenciesMultiSelectDropdownProps {
  selectedOptions: any
  setSelectedOptions: Dispatch<SetStateAction<Option[]>>
  agencyOptions: Option[]
  isDisabled?: boolean
  label?: string
  updateUrlParams?: (key: string, value: string) => string
}

const AgenciesMultiSelectDropdown = ({
  setSelectedOptions,
  selectedOptions,
  agencyOptions,
  isDisabled = false,
  label = 'Filter by Agency',
  updateUrlParams,
}: AgenciesMultiSelectDropdownProps) => {
  const user = useAppSelector(state => state.User.user)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const agenciesParam = searchParams.get(FILTERS.AGENCY)
  const agencies = agenciesParam ? JSON.parse(agenciesParam) : ''
  const [options, setOptions] = useState<Option[]>(agencyOptions)

  useEffect(() => {
    const { options } = disableChildren(selectedOptions)

    setOptions(options)
  }, [selectedOptions])

  useEffect(() => {
    if (agencies.length !== 0) {
      setSelectedOptions(
        options.filter(item => {
          return agencies.includes(item.label)
        }),
      )
    }
  }, [])

  const collectDisabledIds = (
    option: Option,
    selectedValues: Set<string>,
    disabledIds: Set<string>,
  ) => {
    if (selectedValues.has('')) {
      disabledIds.add(option.value)
    }
  }

  const disableRecursive = (
    option: Option,
    selectedValues: Set<string>,
    disabledIds: Set<string>,
  ): Option => {
    const updatedOption = { ...option }
    collectDisabledIds(option, selectedValues, disabledIds)

    if (disabledIds.has('')) {
      updatedOption.disabled = true
    }

    return updatedOption
  }

  const disableChildren = (selectedOptions: Option[]) => {
    const selectedValues = new Set(
      (selectedOptions || []).map(option => option.value),
    )
    const disabledIds = new Set<string>()
    let _options: Option[] = options.map(option => ({
      ...option,
      disabled: false,
    }))

    if (user?.groupId) {
      _options = _options.filter(option => option.level !== 0)
    }

    const allSelected = selectedOptions.some(option => option.label === 'All')
    const inHouseSelected = selectedOptions.some(
      option => option.label === 'In House',
    )

    if (allSelected) {
      return {
        options: _options.map(option => ({
          ...option,
          disabled: option.label !== 'All',
        })),
        disabledIds,
      }
    } else if (inHouseSelected) {
      return {
        options: _options.map(option => ({
          ...option,
          disabled: option.label !== 'In House',
        })),
        disabledIds,
      }
    }
    return {
      options: _options.map(option =>
        disableRecursive(option, selectedValues, disabledIds),
      ),
      disabledIds,
    }
  }

  return (
    <div>
      <label htmlFor='agency' className='form-label'>
        {label}
      </label>
      <GroupMultiSelectWithSearch<Option>
        name='group'
        id='group'
        onChange={option => {
          if (option) {
            const { disabledIds } = disableChildren(option as Option[])
            const allSelected = (option as Option[]).find(
              item => item.value === 'All',
            )
            const inHouseSelected = (option as Option[]).find(
              item => item.value === 'In House',
            )

            if (allSelected) {
              setSelectedOptions([allSelected])
              if (updateUrlParams) {
                navigate(
                  `?${updateUrlParams(
                    FILTERS.AGENCY,
                    JSON.stringify([allSelected].map(item => item.label)),
                  )}`,
                )
              }
            } else if (inHouseSelected) {
              setSelectedOptions([inHouseSelected])
              if (updateUrlParams) {
                navigate(
                  `?${updateUrlParams(
                    FILTERS.AGENCY,
                    JSON.stringify([inHouseSelected].map(item => item.label)),
                  )}`,
                )
              }
            } else {
              const filteredSelectedOptions = (option as Option[]).filter(
                item => !disabledIds.has(item.value),
              )
              setSelectedOptions(filteredSelectedOptions)
              if (updateUrlParams) {
                navigate(
                  `?${updateUrlParams(
                    FILTERS.AGENCY,
                    JSON.stringify(
                      filteredSelectedOptions.map(item =>
                        item.label.toString(),
                      ),
                    ),
                  )}`,
                )
              }
            }
          }
        }}
        placeholder={'Select agencies'}
        hideSelectedOptions={false}
        isDisabled={isDisabled}
        isMulti
        value={selectedOptions}
        isClearable={false}
        isSearchable={true}
        options={options}
      />
    </div>
  )
}

export default AgenciesMultiSelectDropdown
